import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Link } from "@remix-run/react";
import { useState } from "react";

import type { login_notification } from "@prisma/client";
import { ThemeSwitch } from "~/routes/resources+/theme-switch";
import type { AuthenticatedIDs } from "~/types/AuthenticatedIDs";
import type { Theme } from "~/utils/theme.server";
import { Logo } from "./Logo";
import NotificationBell from "./buttons/NotificationBell";

interface HeaderProps {
  login: AuthenticatedIDs | null;
  loginName: string;
  notificationNumber: number;
  notifications: login_notification[];
  theme: Theme | null;
}

export default function Header({
  login,
  loginName,
  notificationNumber,
  notifications,
  theme,
}: HeaderProps) {
  let [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  let navigation = [
    { name: "Tasks", href: "/tasklist", icon: FolderIcon, current: false },
    {
      name: "Taskschema",
      href: "/taskschemalist",
      icon: ServerIcon,
      current: false,
    },
    { name: "Activity", href: "#", icon: SignalIcon, current: false },
    {
      name: "TestTabs",
      href: "/testTabsComponent",
      icon: GlobeAltIcon,
      current: false,
    },
    {
      name: "TestTable",
      href: "/testTableComponent",
      icon: ChartBarSquareIcon,
      current: false,
    },
    {
      name: "Logout",
      href: "/logout",
      icon: Cog6ToothIcon,
      current: true,
    },
  ];

  return (
    <header className="bg-bg-light dark:bg-bg-dark">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 xl:px-8"
        aria-label="Global"
      >
        <div className="flex xl:flex-1"></div>
        <div className="flex xl:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div className="hidden xl:flex xl:flex-1 xl:justify-end text-black dark:text-white">
          <div className="mt-2.5 mr-2">
            <ThemeSwitch userPreference={theme} />
          </div>

          {loginName ? (
            <div className="text-sm font-semibold leading-6 text-black dark:text-white">
              <span className="text-sm font-semibold leading-6 text-black dark:text-white">
                {" "}
                <NotificationBell
                  number={notificationNumber}
                  notifications={notifications}
                  aria-hidden="true"
                ></NotificationBell>
              </span>
            </div>
          ) : (
            <Link to="/login">Login</Link>
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="xl:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-bg-light dark:bg-bg-dark px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <Logo theme={null} />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-black dark:text-white hover:bg-gray-800"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-black dark:text-white hover:bg-gray-800"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
